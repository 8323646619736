.custom_btn_primary {
    background-color: #426DA9 !important;
    // border-radius: 30px !important;
    color: white !important;
    border: 1px solid #426DA9;
    font-size: 12px !important;

    &:hover {
        background-color: white !important;
        color: #426DA9 !important;
        border: 1px solid #426DA9;
    }
}

.required_champs {
    color: red;
}

.settingSidebar {
    display: none;
}

.custom-btn-update {
    color: #87c587;
}

.custom-btn-delete {
    color: #fa7575;
}

.custom-td-action {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.body {
    padding-top: 0px !important;
}

.custom-input {
    // border-radius: 30px;

    &:focus {
        border: 1px solid #b7b7b4;
        box-shadow: none !important;
    }
}

tr>td {
    font-size: 12px !important;
    padding: 5px;
}

.txt-align-center {
    text-align: center;
}

.btn-cancel {
    background-color: #86888a !important;
    // border-radius: 30px !important;
    color: #ffffff !important;
    border: 1px solid #7d8080;
    font-size: 12px !important;

    &:hover {
        background-color: #ffffff !important;
        color: #242525 !important;
    }
}

.modal-footer-custom {
    border-top: 1px solid #eceeee !important;
    padding: 0px;
}

.modal .modal-header {
    background-color: #426DA9 !important;
    padding: 10px !important;
    color: #fff;
}

.modal-content {
    padding: 0px !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
    background-color: #afc6e0 !important;
}

.mat-expansion-panel-body {
    border: 1px solid #89bffd;
}

tfoot {
    border: none !important;

    td {
        border-bottom: 0px !important;

        .span_total_entree {
            color: #426DA9;
            font-weight: bold;
        }

        .pagination-part {
            display: flex;
            align-items: center;
        }
    }
}

.swal2-icon.swal2-warning.swal2-icon-show {
    display: none !important;
}

.swal2-container.swal2-center>.swal2-popup {
    width: 370px;
    font-size: 12px;
}

.custom-table-head {
    th {
        a {
            cursor: pointer;
        }

    }
}

.body {
    &.table-responsive {
        padding: 11px !important;
    }
}

.isInactif {

    color: rgb(247, 107, 107);
}

label {
    font-weight: bold !important;
}

.custom-table-head {
    th {
        background-color: white !important;
        color: #416BA6 !important;
        font-weight: 800;

    }

}

.custom-btn-details {
    color: #858585;
    cursor: pointer;
}

.form-control:disabled {
    background-color: #e9ecef !important;
}

.custom-fieldset {
    border: 1px solid #eceeee;
    border-radius: 5px;
    padding: 8px;

    legend {
        font-size: 11px;
        color: #fb5a5a;
    }
}

.theme-white .sidebar .menu .list li {
    cursor: pointer;
}

td,
th {
    border-color: white;
    border-style: none;
    border-width: 0;
}

.table {
    tr:nth-child(odd) {
        background-color: #d1dff3;
    }

    tr:nth-child(even) {
        background-color: #FFFFFF;
    }
}

.table-bordered thead tr th {
    background: #edf0f5;
}

.required_champs{
    color: red;
}